import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Badge, Col, Row } from 'reactstrap';

import {
  PageHeader,
  RoleManager,
  Section,
  SectionBody,
  SectionHeader,
  Spinner,
  Translation,
} from 'components';
import { api, urlSchema, useFetch } from 'utils';

const Read = ({ match }) => {
  const { params } = match;
  const [product, isProductLoading] = useFetch(
    api.manager.getProductDetails(params.productId),
  );

  return (
    <>
      <PageHeader>
        <Translation
          tagName='h1'
          id='Screens.Products.Read.Title'
          values={{ name: product?.label }}
        />
        <RoleManager entity='Products' right='CRUD'>
          <Link
            className='btn btn-primary'
            to={`${urlSchema.products.update}/${product.idProduct}`}
          >
            <Translation id='Buttons.Edit' />
          </Link>
        </RoleManager>
      </PageHeader>
      <Row>
        <Col lg='8'>
          <Section>
            {isProductLoading ? (
              <Spinner color='primary' size='lg' />
            ) : (
              <>
                <SectionHeader>
                  <h2 className='d-inline-block align-middle mr-3'>
                    {product.code}
                  </h2>
                </SectionHeader>
                <SectionBody>
                  <div className='model'>
                    <Row>
                      <Col lg='6'>
                        {product.rawFile ? (
                          <figure className='model__logo'>
                            <img
                              alt={`${product.label}'s logo`}
                              src={`data:image/*;base64, ${product.rawFile}`}
                            />
                          </figure>
                        ) : (
                          <div className='model__no-logo'>
                            <Translation tagName='p' id='Shared.NoLogo' />
                          </div>
                        )}
                        <h3 className='mb-3'>
                          <Badge color={product.valid ? 'success' : 'danger'}>
                            {product.valid ? (
                              <>
                                <i className='uil uil-check mr-1' aria-hidden />
                                <Translation id='Shared.Valid' />
                              </>
                            ) : (
                              <>
                                <i
                                  className='uil uil-multiply mr-1'
                                  aria-hidden
                                />
                                <Translation id='Shared.Invalid' />
                              </>
                            )}
                          </Badge>
                        </h3>
                        <div className='model__actions'>
                          <Link
                            className='btn btn-outline-primary btn-sm'
                            to={`${urlSchema.patients.listMD}?Products=${product.idProduct}`}
                          >
                            <Translation id='Buttons.SeeSubscribers' />
                          </Link>
                          {product.refVerificationTypeNavigation.code ===
                            'CODE' && (
                            <>
                              <a
                                className='btn btn-outline-primary btn-sm'
                                href={`${urlSchema.vouchersGroupsCreate}?idProduct=${product.idProduct}`}
                                rel='noopener noreferrer'
                              >
                                <Translation id='Buttons.GenerateVoucher' />
                              </a>
                              <Link
                                className='btn btn-outline-primary btn-sm'
                                to={`${urlSchema.vouchersGroups}?refProduct=${product.idProduct}`}
                              >
                                <Translation id='Buttons.SeeVouchers' />
                              </Link>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col className='mt-sm-3' lg='6'>
                        {product.nbVouchers > 0 && (
                          <Link
                            className='btn btn-outline-warning btn-sm mb-3'
                            to={`${urlSchema.vouchers}?refProduct=${product.idProduct}`}
                          >
                            <i
                              aria-hidden
                              className=' align-middle uil uil-tag-alt uil--sm'
                            />
                            <span className='d-inline-block align-middle ml-1'>
                              <Translation
                                id='Models.Product.Vouchers'
                                values={{ number: product.nbVouchers }}
                              />
                            </span>
                          </Link>
                        )}
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.Code' />
                          </h4>
                          <p className='model__value'>{product.code}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.Label' />
                          </h4>
                          <p className='model__value'>{product.label}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.Tag' />
                          </h4>
                          <p className='model__value'>{product.productTag}</p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.ExpirationDate' />
                          </h4>
                          <p className='model__value'>
                            {product.dateExpiration ? (
                              moment(product.dateExpiration).format('LL')
                            ) : (
                              <Translation id='Shared.Blank' />
                            )}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.MaxQuestionsCount' />
                          </h4>
                          <p className='model__value'>
                            {product.maxQuestionCount ?? (
                              <Translation id='Shared.Blank' />
                            )}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.Duration' />
                          </h4>
                          <p className='model__value'>
                            {product.duration ?? (
                              <Translation id='Shared.Blank' />
                            )}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.Client' />
                          </h4>
                          <Link
                            className='model__value'
                            to={`${urlSchema.clients.read}/${product.refClient}`}
                          >
                            {product.clientLabel}
                          </Link>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.SellsyClient' />
                          </h4>
                          <p className='model__value'>
                            {product.labelClientSellsy ? (
                              `${product.labelClientSellsy} (${product.refClientSellsy})`
                            ) : (
                              <Translation id='Shared.Blank' />
                            )}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation id='Models.Product.VerificationType' />
                          </h4>
                          <p className='model__value'>
                            {product.refVerificationTypeNavigation.code}
                          </p>
                        </div>
                        <div className='model__property'>
                          <h4 className='model__title'>
                            <Translation
                              id='Model.CreationDate'
                              values={{
                                date: moment(product.dateCreation).format('LL'),
                              }}
                            />
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </SectionBody>
              </>
            )}
          </Section>
        </Col>
      </Row>
    </>
  );
};

export default Read;
