import { ProtectedRoute } from 'components';
import { Switch } from 'react-router-dom';
import { urlSchema } from 'utils';

import Read from './Read';

const routes = [
  {
    path: `${urlSchema.dialogues.read}/:conversationId`,
    component: Read,
    right: 'Reader',
  },
  { path: '/', component: Read, right: 'Reader' },
];

const Dialogues = () => (
  <Switch>
    {routes.map((item) => (
      <ProtectedRoute entity='DialogueConfidential' key={item.path} {...item} />
    ))}
  </Switch>
);

export default Dialogues;
