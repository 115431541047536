import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import moment from 'moment';
import qs from 'qs';
import { toaster } from '@teladochealth/components';

import {
  Button,
  PageHeader,
  Section,
  SectionBody,
  Translation,
} from '../../../components';
import { Doctor } from '../../../models';
import { service } from '../../../services';
import { api, urlSchema } from '../../../utils';
import validationSchema from '../../../models/Doctor/validationSchema';
import { ButtonsWrapper } from '../../../themes/jupiter/elements';
import initialValues from './initialValues';

const Create = ({ history }) => {
  const { formatMessage } = useIntl();
  const parameters = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const [formLogoData, setFormLogoData] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    const post = async (newValues, newProfiles) => {
      try {
        const { data } = await service.post(api.doctors.post, {
          ...newValues,
          newProfiles,
        });

        if (data.succeeded === false) {
          throw new Error(data.errorSet[0].code);
        }

        if (formLogoData !== null) {
          const { data: dataSignature } = await service.post(
            api.doctors.postScanSignature(data.id),
            formLogoData,
          );

          if (dataSignature.succeeded === false) {
            throw new Error(dataSignature.errorSet[0].code);
          }
        }

        toaster({
          title: <Translation id='Message.Success' />,
          text: <Translation id='Screens.Doctors.Create.SuccesBody' />,
          type: 'success',
        });

        history.push(`${urlSchema.doctors.read}/${data.id}`);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        toaster({
          title: <Translation id='Message.Error' />,
          text: error.message,
          type: 'error',
        });
      }
    };

    const formattedValues = values;
    const selectedProfiles = values.selectedProfiles.map(
      (element) => element.value,
    );
    formattedValues.birthdate = moment(values.birthdate).format('YYYY-MM-DD');

    // Cast boolean for radiobutton
    formattedValues.cpsCard = JSON.parse(values.cpsCard);
    formattedValues.cpsReader = JSON.parse(values.cpsReader);
    formattedValues.prescriptionBook = JSON.parse(values.prescriptionBook);
    formattedValues.generateFeesNotes = JSON.parse(values.generateFeesNotes);
    // Formatting phone numbers for back-end regex
    formattedValues.mobileNumber = values.mobileNumber.replace('+', '00');
    formattedValues.phoneNumber = values.phoneNumber.replace('+', '00');

    post(formattedValues, selectedProfiles);
  };

  return (
    <>
      <PageHeader>
        <Translation id='Screens.Doctors.Create.Title' tagName='h1' />
      </PageHeader>
      <Section>
        <SectionBody>
          <Formik
            initialValues={{ ...initialValues, ...parameters }}
            validationSchema={() => {
              validationSchema(formatMessage);
            }}
            {...{ onSubmit }}
          >
            {({ isSubmitting, values, ...properties }) => (
              <Form>
                <Doctor
                  {...{ values }}
                  {...{ setFormLogoData }}
                  {...properties}
                />
                <ButtonsWrapper bottom>
                  <Button
                    color='primary'
                    isProcessing={isSubmitting}
                    label='Create'
                    size='lg'
                    data-tracking='DoctorCreateSubmit'
                    type='submit'
                  />
                </ButtonsWrapper>
              </Form>
            )}
          </Formik>
        </SectionBody>
      </Section>
    </>
  );
};

Create.displayName = 'Screens_Doctors_Create';

export default Create;
